import { object, string } from 'prop-types';
import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import * as sharetribeSdk from 'sharetribe-flex-sdk';

import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { createResourceLocatorString } from '../../../util/routes';
import * as validators from '../../../util/validators';

import classNames from 'classnames';
import { FieldTextInput, Form, PrimaryButton } from '../..';
import Modal from '../../Modal/Modal';
import css from './OpenModalFormButton.module.css';

const CLIENT_ID = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;
const renderInquiryForm = formRenderProps => {
    const intl = useIntl();
    const history = useHistory();
    const routeConfiguration = useRouteConfiguration();

    const [inProgress, setInProgress] = useState(false);
    const { formId, listingId, invalid } = formRenderProps;
    const submitInProgress = inProgress;
    const submitDisabled = invalid || submitInProgress;
    const sdk = sharetribeSdk.createInstance({ clientId: CLIENT_ID });

    const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
    });
    const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
    });
    const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
    });
    const emailRequired = validators.required(emailRequiredMessage);
    const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
    });
    const emailValid = validators.emailFormatValid(emailInvalidMessage);

    const handleSubmit = (event) => {
        event.preventDefault();

        const inquiryMessage = [
            `[name]: ${event.target.name.value}`,
            `[email]: ${event.target.email.value}`,
            `[company address]: ${event.target.companyAddress.value}`,
            `[shipping address]: ${event.target.shippingAddress.value}`,
            `[quantity]: ${event.target.quantity.value}`,
            `[comments]: ${event.target.comments.value}`
        ].join('\n');

        const bodyParams = {
            "transition": "transition/inquire-without-payment",
            "processAlias": "default-inquiry/release-1",
            "params": {
                "listingId": listingId,
                "protectedData": {
                    "inquiryMessage": inquiryMessage,
                    "unitType": "inquiry"
                }
            }
        };

        const queryParams = {
            include: ['provider'],
            expand: true,
        };

        setInProgress(true);
        return sdk.transactions
            .initiate(bodyParams, queryParams)
            .then(response => {
                setInProgress(false);
                const txId = response.data.data.id;
                history.push(createResourceLocatorString('OrderDetailsPage',
                    routeConfiguration,
                    { id: txId.uuid },
                    {}
                ));

                return txId;
            })
            .catch(e => console.error(e));
    }

    return (
        <Form id={formId} onSubmit={handleSubmit} className={css.root}>
            <div className={css.formContainer}>
                <FieldTextInput
                    type="text"
                    id={'name'}
                    name="name"
                    label={"Name"}
                    placeholder={"Your name"}
                    validate={validators.required("Name is required")}
                />
                <FieldTextInput
                    type="email"
                    id={'email'}
                    name="email"
                    autoComplete="email"
                    label={emailLabel}
                    placeholder={emailPlaceholder}
                    validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <FieldTextInput
                    type="text"
                    id={'companyAddress'}
                    name="companyAddress"
                    label={"Company address"}
                    placeholder={""}
                    validate={validators.required("Company address is required")}
                />
                <FieldTextInput
                    type="text"
                    id={'shippingAddress'}
                    name="shippingAddress"
                    label={"Shipping address"}
                    placeholder={""}
                    validate={validators.required("Shipping address is required")}
                />
                <FieldTextInput
                    type="number"
                    id={'quantity'}
                    name="quantity"
                    label={"Order quantity for quote"}
                    placeholder={"1"}
                />
                <FieldTextInput
                    type="textarea"
                    id={'comments'}
                    name="comments"
                    label={"Comments"}
                    placeholder={"Describe your listing in more detail"}
                />
            </div>
            <div className='mt-4'>
                <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                    <FormattedMessage id="InquiryWithoutPaymentForm.requestQuote" />
                </PrimaryButton>
            </div>
        </Form>
    );
};

{/* primary button opens the form */ }
const OpenModalFormButton = props => {
    const intl = useIntl();
    const initialValues = {};
    const [inquiryModalOpen, setInquiryModalOpen] = useState(false);
    const { listingId, className } = props;
    const classes = classNames(className, css.container);

    return (
        <div className={classes}>
            <PrimaryButton onClick={() => setInquiryModalOpen(true)} type="button">
                <FormattedMessage id="InquiryWithoutPaymentForm.requestQuote" />
            </PrimaryButton>
            <Modal
                id="ListingPage.inquiry"
                isOpen={inquiryModalOpen}
                onClose={() => setInquiryModalOpen(false)}
                onManageDisableScrolling={() => { }}
                usePortal
            >
                <FinalForm initialValues={initialValues}
                    formId="ListingPage.inquiry"
                    onSubmit={() => { }}
                    intl={intl}
                    render={(formProps) => renderInquiryForm({ ...formProps, listingId })}
                />
            </Modal>
        </div>
    );

};

OpenModalFormButton.defaultProps = {
    rootClassName: null,
    className: null,
    listingId: null,
};

OpenModalFormButton.propTypes = {
    rootClassName: string,
    className: string,
    listingId: object.isRequired,
};

export default OpenModalFormButton;
